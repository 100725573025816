import { Constants, MessageUtil } from './../../helper/messages';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Observable } from 'rxjs';
import { filter, map, shareReplay } from 'rxjs/operators';
import {  Router, RoutesRecognized } from '@angular/router';
import { AppService } from 'src/app/app.service';
import { BaseService } from 'src/app/api/base.service';
// import Chart from 'chart.js';

@Component({
  selector: 'app-base-app',
  templateUrl: './base-app.component.html',
  styleUrls: ['./base-app.component.scss'],
  // encapsulation: ViewEncapsulation.None,
})
export class BaseAppComponent implements OnInit {
  showFiller: boolean = true;
  openSideNav: boolean = false
  fullname: string

  isHandset$: Observable<boolean> = this.breakpointObserver
    .observe(Breakpoints.Handset)
    .pipe(
      map((result) => result.matches),
      shareReplay()
    );

  activeComponent: string = 'Overview';

  @Output() public reload: EventEmitter<any> = new EventEmitter<any>()
  imagePreview: string;

  constructor(
    private breakpointObserver: BreakpointObserver,
    public router: Router,
    private app: AppService,
    private base: BaseService,    
  ) {    
    this.app.titleBase().subscribe(res => {
      this.activeComponent = res
    })
  }

  ngOnInit(): void {
    this.fullname = this.app.helperService.getFullname()
    let name  = this.fullname?.split(' ')
    if (name)
      this.fullname = name[0] + ' '+ name[1]
    this.getUsersImage()
  }
  
  getUsersImage() {
    let id = this.app.helperService.getUserAppId()
    let category = "student"
    let baseUrl = this.base._baseUrl
    this.imagePreview = baseUrl + `/preview/photo?category=${category}&id=${id}`
  }

  logout() {
    this.app.logout(Constants.LOGIN_USER)
  }
  gotoProfile(){
    this.router.navigate(["app/setting"])
  }

  openSales() {
    this.openSideNav = true
  }

  toggle() {
    event.stopPropagation()
    this.openSideNav = true
  }

  reloadUsers() {
    this.reload.emit()
  }

  closeSidenav() {
    this.openSideNav = false
  }

  menuList = [
    { name: 'Dashboard', route: 'dashboard', icon: 'home', tip:'Student Dashboard' },
    { name: 'Biodata', route: 'biodata', icon: 'people_alt', tip: 'Biodata' },
    { name: 'Registration', route: 'course-reg', icon: 'contact_page', tip: 'Course Registration' },
    { name: 'Result', route: 'result', icon: 'library_books', tip: 'Semester Result' },
    // { name: 'Registration Report', route: 'course-reg-report', icon: 'people_alt', tip: 'Course Registration Report' },
    // { name: 'Result Report', route: 'result_report', icon: 'contact_page', tip: 'Result Report' },
    { name: 'Examination Clearance', route: 'exam-clearance', icon: 'receipt', tip: 'Examination Clearance' },
    { name: 'Setting ', route: 'setting', icon: 'settings', tip: 'Student Setting' },
  ];
  
  show() {
    this.showFiller = this.showFiller = !this.showFiller;
  }
}
